import { Injectable } from '@angular/core';
import { catchError, first, map, Observable } from 'rxjs';
import { ModuleBackend } from '@enums/index';
import { ICrudResponse, IInvoicing, IFilter, IPagination, IResponse } from '@interfaces/index';
import { GenericCrudService } from '@common/classes/generic-crud.service';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class InvoicingService extends GenericCrudService<IInvoicing> {
  readonly filterObj: IFilter[] = [];
  private clients$: Observable<any[]>;

  constructor() {
    super(ModuleBackend.INVOICE_SALE);
    this.clients$ = this.readAllClients();
    this.filterObj = [
      {
        label: "N° Doc",
        controlName: "invoiceSaleNumber",
        type: "text"
      },
      {
        label: "Cliente que Recibe",
        controlName: "auxiliaryId",
        type: "select",
        data: this.clients$
      }
    ];
  }

  override readAll(pagination?: Pick<IPagination, "currentPage" | "skip">, extraKeys?: {} | {}[]): Observable<ICrudResponse<IInvoicing>> { return super.readAll(pagination, extraKeys); }

  readAllClients(): Observable<any[]> {
    return this.http.get<IResponse<any[]>>(`
          ${environment.API_URL}/${ModuleBackend.ACCOUNTING_AUXILIARY}/auxiliar/type?typeAuxiliaryName=Clientes`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        map(x => x.statusCode === 200 ? x.data : []),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }

  downloadPDF(id: number): Observable<Blob> {
    return this.http.get(`
      ${environment.API_URL}/${ModuleBackend.INVOICE_SALE}/${id}/pdf`,
      { headers: this.headerComplement.complementHeaderRaw(), responseType: 'blob' }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }
}
