import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationApp } from '@navigation/*';
import { MenuStateService } from '@services/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu-floating',
  templateUrl: './menu-floating.component.html',
  styleUrls: ['./menu-floating.component.scss']
})
export class MenuFloatingComponent implements OnInit, OnDestroy {

  services = [
    { name: 'Plan de Cuenta', link: '/dashboard/plan-accounts', subitems: [{ name: 'Ver Plan de Cuentas', link: '/plan-de-cuenta' }] },
    { name: 'Módulo de Auxiliares', link: '/dashboard/modules-management', subitems: [{ name: 'Listado de Módulos', link: '/modulos' }, { name: 'Creación de Auxiliares', link: '/crear-auxiliar' }, { name: 'Lista de Auxiliares', link: '/lista-auxiliares' }] },
    { name: 'Registro de Operaciones', isChildrenBackend: true,
    },
    { name: 'Control de Registros', subitems: [{ name: 'Libro Diario', link: '/dashboard/record-control/journal' }, { name: 'Listado de Comprobante', link: '/dashboard/record-control/voucher-list' }, { name: 'Listado de Morosos', link: '/dashboard/record-control/list-defaulters' }] },
    // { name: 'Módulo de Inventario', subitems: [{ name: 'Registro de Producto', link: '/dashboard/inventory/products/create' }, { name: 'Lista de Productos', link: '/dashboard/inventory/products/list' }, { name: 'Registros de Entrada', link: '/dashboard/inventory/orders/entry/list' }, { name: 'Registros de Salida', link: '/dashboard/inventory/orders/departure/list' }, { name: 'Resumen de Registros', link: '/dashboard/inventory/orders/summary/list' }] },
    { name: 'Módulo de Inventario', subitems: [{ name: 'Registro de Producto', link: '/dashboard/inventory/products/create' }, { name: 'Lista de Productos', link: '/dashboard/inventory/products/list' }] },
    { name: 'Órdenes de Compras', subitems: [{ name: 'Solicitud de Orden', link: '/dashboard/purchase-orders/order-request' }, { name: 'Orden de Compra', link: '/dashboard/purchase-orders/purchase-order' }, { name: 'Recepcion de Órdenes', link: '/dashboard/purchase-orders/order-receipts' }] },
    // { name: 'Facturación', subitems: [{ name: 'Presupuesto', link: '/presupuesto' }, { name: 'Notas de Entrega', link: '/notas-entrega' }, { name: 'Facturas', link: `/${NavigationApp.DASHBOARD}/${NavigationApp.BILLING}` }] },
    { name: 'Facturación', link: '/dashboard/billing/invoice-request' },
    { name: 'Notas de Crédito', link: '/dashboard/credits-notes/return-notes' },
    // { name: 'Reportes', subitems: [{ name: 'Listado de Reportes', link: '/reportes' }] }
    { name: 'Reportes', link: '/dashboard/reports' }
  ];

  userRoutes = [];

  roleRoutes = [
    { name: 'Crear Rol', link: 'users/roles/create' },
    { name: 'Ver listado de roles', link: 'users/roles/list' }
  ];

  menuItems = [
    { name: 'HOME', link: 'home', label: 'Inicio', icon: '/assets/icon/home.svg', iconSelected: '/assets/icon/home_select.svg', hasSubMenu: false },
    { name: 'SERVICES', label: 'Servicios', icon: '/assets/icon/dashboard.svg', iconSelected: '/assets/icon/dashboard_select.svg', hasSubMenu: true },
    { name: 'USERS', link: 'users', label: 'Usuarios', icon: '/assets/icon/person.svg', iconSelected: '/assets/icon/person_select.svg', hasSubMenu: false }
  ];

  rootDinamic: string;
  activeSubMenu: boolean;
  itemSelectSubMenuList: string;

  private subscriptions: Subscription[] = [];

  constructor(
    public router: Router,
    private menuStateService: MenuStateService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.menuStateService.rootDinamic$.subscribe(rootDinamic => this.rootDinamic = rootDinamic),
      this.menuStateService.activeSubMenu$.subscribe(activeSubMenu => this.activeSubMenu = activeSubMenu),
      this.menuStateService.itemSelectSubMenuList$.subscribe(itemSelectSubMenuList => this.itemSelectSubMenuList = itemSelectSubMenuList)
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  changeItemMenuFloating(name: string, hasSubMenu: boolean) {
    this.menuStateService.setRootDinamic(name);
    this.menuStateService.setActiveSubMenu(hasSubMenu);
  }
}
