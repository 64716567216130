import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericCrudService } from '@common/classes/generic-crud.service';
import { HttpComplement } from '@common/functions/http-complement';
import { MessagesAlerts } from '@common/index';
import { ModuleBackend } from '@enums/modules-backend.enum';
import { environment } from '@environments/environment';
import { ICrudResponse, IDeliveryNote, IErrorResponse, IFilter, IPagination, IResponse } from '@interfaces/*';
import { BehaviorSubject, catchError, first, map, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CreditsNotesService extends GenericCrudService<IDeliveryNote> {
  
  readonly filterObj: IFilter[] = [];
  private clients$: Observable<any[]>;

  constructor() {
    super(ModuleBackend.CREDIT_NOTES_CLIENT);
    this.filterObj = [
      {
        label: "N° Doc",
        controlName: "deliveryNoteNumber",
        type: "text"
      },
      {
        label: "Cliente que Recibe",
        controlName: "auxiliaryId",
        type: "select",
        data: this.clients$
      },
      {
        label: "Monto Total",
        controlName: "totalAmount",
        type: "amount"
      }
    ];
  }

  override readAll(pagination?: Pick<IPagination, "currentPage" | "skip">, extraKeys?: {} | {}[]): Observable<ICrudResponse<IDeliveryNote>> { return super.readAll(pagination, extraKeys); }


  readAllCreditNotes(): Observable<any[]> {
    return this.http.get<IResponse<any[]>>(`
        ${environment.API_URL}/${ModuleBackend.CREDIT_NOTES_CLIENT}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        map(x => x.statusCode === 200 ? x.data : []),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }

  downloadPDF(id: number): Observable<Blob> {
    return this.http.get(`
      ${environment.API_URL}/${ModuleBackend.CREDIT_NOTES_CLIENT}/${id}/pdf`,
      { headers: this.headerComplement.complementHeaderRaw(), responseType: 'blob' }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }

}
