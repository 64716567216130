import { ModuleBackend } from 'src/enums/modules-backend.enum';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { GenericCrudService } from '@common/classes/generic-crud.service';
import { IPagination } from '@interfaces/*';
import { catchError, first, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ReportsService extends GenericCrudService<any> {

  constructor() { super(ModuleBackend.REPORTS_CLIENT); }

  override download(pagination?: Pick<IPagination, 'currentPage' | 'skip'>, extraKeys?: {} | {}[], type?: string): Observable<Blob> {
    return this.http.post(`
      ${environment.API_URL}/${ModuleBackend.REPORTS_CLIENT}/${type}`,
      extraKeys,
      { headers: this.headerComplement.complementHeaderRaw(), responseType: 'blob' }
    )
      .pipe(
        first(),
        catchError(async ({ error }) => {
          const errorBlob = error as Blob;
          this.onError(JSON.parse(await errorBlob.text()));
          throw error;
        })
      );
  }
}
