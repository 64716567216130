import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, first, map, Observable, pairwise, startWith } from 'rxjs';
import { ModuleBackend, TypeInvoiceQuotedList, TypeInvoiceRequestList } from '@enums/index';
import { GenericCrudService } from '@common/classes/generic-crud.service';
import { IPagination, ICrudResponse, IResponse, IInvoiceQuoted } from '@interfaces/*';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class InvoiceQuotedService extends GenericCrudService<IInvoiceQuoted> {
  private typeInvoiceQuotedList$: BehaviorSubject<TypeInvoiceQuotedList>;
  private requestSelected$: BehaviorSubject<IInvoiceQuoted>;

  constructor() {
    super(ModuleBackend.INVOICE_QUOTED);
    this.typeInvoiceQuotedList$ = new BehaviorSubject(TypeInvoiceQuotedList.PENDING);
    this.requestSelected$ = new BehaviorSubject(null);
  }

  override create(data: Partial<IInvoiceQuoted>): Observable<IResponse<IInvoiceQuoted>> { return super.create(data); }
  override readOne(id: number): Observable<IResponse<IInvoiceQuoted>> { return super.readOne(id); }
  override readAll(pagination: Pick<IPagination, 'currentPage' | 'skip'>, extraKeys?: {} | {}[]): Observable<ICrudResponse<IInvoiceQuoted>> {
    return super.readAll(pagination, extraKeys);
  }
  readAllClients(): Observable<IResponse<any[]>> {
    return this.http.get<IResponse<any[]>>(`
      ${environment.API_URL}/${ModuleBackend.ACCOUNTING_AUXILIARY}/auxiliar/type?typeAuxiliaryName=Clientes`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }
  override update(id: number, data: Partial<IInvoiceQuoted>): Observable<IResponse<IInvoiceQuoted>> { return super.update(id, data); }
  updateStatus(id: number, data: { status: TypeInvoiceRequestList, description: string }): Observable<IResponse<IInvoiceQuoted>> {
    return this.http.patch<IResponse<IInvoiceQuoted>>(`
    ${environment.API_URL}/${ModuleBackend.INVOICE_REQUEST}/${id}/chage-status`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }

  downloadPDF(id: number): Observable<Blob> {
    return this.http.post(`
      ${environment.API_URL}/${ModuleBackend.INVOICE_QUOTED}/pdf`,
      { quotationId: id },
      { headers: this.headerComplement.complementHeaderRaw(), responseType: 'blob' }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }

  aprove(id: number, data: any): Observable<any> {
    return this.http.patch<IResponse<IInvoiceQuoted>>(`
    ${environment.API_URL}/${ModuleBackend.INVOICE_QUOTED}/${id}/approve`,
      data,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }

  readAllExpiredList(pagination: any): Observable<IResponse<any[]>> {
    return this.http.get<IResponse<any[]>>(`
      ${environment.API_URL}/${ModuleBackend.INVOICE_QUOTED}/all/expired?page=${ pagination?.page }&skip=${ pagination?.skip }`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }

  quot

  setTypeInvoiceQuotedList(type: TypeInvoiceQuotedList) { this.typeInvoiceQuotedList$.next(type); }
  getTypeInvoiceQuotedList() { return this.typeInvoiceQuotedList$.getValue(); }
  getTypeInvoiceQuotedListObservable(): Observable<{ previousState: TypeInvoiceQuotedList; currentState: TypeInvoiceQuotedList; }> {
    return this.typeInvoiceQuotedList$.asObservable()
      .pipe(
        startWith(TypeInvoiceQuotedList.NONE),
        pairwise(),
        map(([previousState, currentState]) => ({ previousState, currentState }))
      );
  }
  setQuoted(quoted: IInvoiceQuoted) { this.requestSelected$.next(quoted); }
  getQuoted() { return this.requestSelected$.getValue(); }
  readAllPriceList(): Observable<IResponse<any[]>> {
    return this.http.get<IResponse<any[]>>(`
      ${environment.API_URL}/${ModuleBackend.PRICE_LIST}?page=${1}&skip=${5000}`,
      { headers: this.headerComplement.complementHeaderRaw() }
    )
      .pipe(
        first(),
        catchError(({ error }) => {
          this.onError(error);
          throw error;
        })
      );
  }
  registerPriceList(Module: ModuleBackend, data: any){
    return this.http.post(
        `${environment.API_URL}/${Module}`,
        data,
        this.headerComplement.complementHeader()
    );
  }

}
