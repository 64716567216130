import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, first, map, Observable, pairwise, startWith } from 'rxjs';
import { ModuleBackend, ReturnNotesStatus } from '@enums/index';
import { GenericCrudService } from '@common/classes/generic-crud.service';
import { IPagination, ICrudResponse, IResponse, IReturnNotes } from '@interfaces/*';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ReturnNotesService extends GenericCrudService<IReturnNotes> {
  private typeReturnNotesList$: BehaviorSubject<ReturnNotesStatus>;
  private requestSelected$: BehaviorSubject<IReturnNotes>;

  constructor() {
    super(ModuleBackend.RETURN_NOTES_CLIENT);
    this.typeReturnNotesList$ = new BehaviorSubject(ReturnNotesStatus.ISSUED);
    this.requestSelected$ = new BehaviorSubject(null);
  }

  override readAll(pagination: Pick<IPagination, 'currentPage' | 'skip'>, extraKeys?: {} | {}[]): Observable<ICrudResponse<IReturnNotes>> {
    return super.readAll(pagination, extraKeys);
  }

  setTypeReturnNotesList(type: ReturnNotesStatus) { this.typeReturnNotesList$.next(type); }
  getTypeReturnNotesList() { return this.typeReturnNotesList$.getValue(); }
  getTypeReturnNotesListObservable(): Observable<{ previousState: ReturnNotesStatus; currentState: ReturnNotesStatus; }> {
    return this.typeReturnNotesList$.asObservable()
      .pipe(
        startWith(ReturnNotesStatus.NONE),
        pairwise(),
        map(([previousState, currentState]) => ({ previousState, currentState }))
      );
  }
  setRequest(request: IReturnNotes) { this.requestSelected$.next(request); }
  getRequest() { return this.requestSelected$.getValue(); }
  registerReturnNote(Module: ModuleBackend, data: any){
    return this.http.post(
        `${environment.API_URL}/${Module}`,
        data,
        this.headerComplement.complementHeader()
    );
  }
}
